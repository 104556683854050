
	import { Component, Provide, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as SurveyMutations } from '@pixcap/ui-core/store/survey';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
	import { WebSocketManager } from '@pixcap/ui-core/services/socketmanager';
	import { mutationsWrapper as AppMutations } from '@pixcap/ui-core/store/app';
	import logger from '@pixcap/ui-core/helpers/logger';
	import { storeOriginSources } from '@pixcap/ui-core/utils/WindowUtils';
	import {
		AppState,
		FreebiesTypes,
		Getters as AppGetters,
		GetterTypes as AppGetterTypes,
		NAMESPACE as APP_NAMESPACE,
		SpecialPromotionType,
	} from '@pixcap/ui-core/models/store/app.interface';
	import AppGlobals from '@/components/AppGlobals.vue';
	import BackToTopButton from '@/components/pixcap-library/shared/BackToTopButton.vue';
	import { BACK_TO_TOP_SCROLL_OFFSET } from '@/constants/app.constants';
	import { checkScrollToButtom } from '@/utils/scrollUtils';
	import { beforeRouteEnter } from '@/utils/route';
	import PromotionHeader from '@/components/layouts/default/PromotionHeader.vue';
	import { IOrganismState, NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';
	import ExploreHeader from '@/components/layouts/default/ExploreHeader.vue';
	import { appUtilities } from '@/modules/appUtilities';
	import { SHOULD_SHOW_REFERRAL_MODAL } from '@pixcap/ui-core/constants/auth.constants';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import ExploreFooter from '@/components/layouts/default/ExploreFooter.vue';
	import CodePromotionBanner from '@/components/layouts/default/CodePromotionBanner.vue';

	@Component({
		name: 'Default',
		components: {
			CodePromotionBanner,
			ExploreFooter,
			PromotionHeader,
			BackToTopButton,
			ExploreHeader,
			AppGlobals,
		},
		beforeRouteEnter(to, from, next) {
			beforeRouteEnter(to, from, next);
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				surveySubmitted: (state: UserState) => (state.personalData ? state.personalData.user.metadata.surveySubmitted : true),
				referralsInfo: (state: UserState) => state.referralsInfo,
			}),
			...mapGetters(USER_NAMESPACE, {
				isUnsubscribedUser: UserGetterTypes.IS_UNSUBSCRIBED_USER,
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
			...mapState(APP_NAMESPACE, {
				abTesting: (state: AppState) => state.abTesting,
			}),
			...mapGetters(APP_NAMESPACE, {
				currentPromotion: AppGetterTypes.CURRENT_PROMOTION,
				codePromotion: AppGetterTypes.CODE_PROMOTION,
			}),
			...mapState(ORGANISM_NAMESPACE, {
				showPromotionHeader: (state: IOrganismState) => state.showPromotionHeader,
			}),
		},
	})
	export default class Default extends Vue {
		surveySubmitted: boolean;
		_handleWindowVisibilityChange;
		_handleScreenSize;

		abTesting: AppState['abTesting'];
		showPromotionHeader: IOrganismState['showPromotionHeader'];
		referralsInfo: UserState['referralsInfo'];
		currentPromotion: ReturnType<AppGetters[AppGetterTypes.CURRENT_PROMOTION]>;
		codePromotion: ReturnType<AppGetters[AppGetterTypes.CODE_PROMOTION]>;
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;

		@ProvideReactive() isMobileScreen: boolean = false;
		@ProvideReactive() isTabletScreen: boolean = false;
		@ProvideReactive() isDesktopScreen: boolean = true;
		@ProvideReactive() windowInnerWidth: number = 0;
		@ProvideReactive() isScrolled = false;
		@ProvideReactive() abTestingVariant: string[] = null;

		@Provide()
		handleScrollBack(isSmoothBehavior = false, offsetTop = 0) {
			if (process.client && this.$refs?.refMainLayout) {
				const $el = this.$refs.refMainLayout as HTMLElement;
				const behavior = (isSmoothBehavior ? 'smooth' : 'instant') as any;
				$el.scrollTo({ top: offsetTop || 0, behavior });
			}
		}

		@Watch('surveySubmitted', { immediate: true })
		showOnboardingModal() {
			if (!this.surveySubmitted) {
				SurveyMutations.setShowOnboardingModal(this.$store, true);
			}
		}

		@Watch('isProOrAbove', { immediate: true })
		handleShowPromotion() {
			this.handleShowPromotionBanner();
		}

		@Watch('watermarkInfos')
		checkForWatermarkingInfos() {
			if (!this.surveySubmitted) return;
			if (appUtilities.$services.localStorageManager.read(SHOULD_SHOW_REFERRAL_MODAL) !== 0) {
				UserMutations.setShowReferralModal(this.$store, true);
			}
		}

		get watermarkInfos() {
			return this.referralsInfo.rewards.find((reward) => reward.name === FreebiesTypes.WATERMARK_FREE);
		}

		get isErrorPage() {
			let error = null;
			if (process.server) {
				error = this.$nuxt.context.ssrContext.nuxt.error;
			} else {
				error = this.$nuxt.context.nuxtState.error;
			}
			return error != null;
		}

		get isShowCodePromotionBanner() {
			return this.codePromotion;
		}

		get isShowPromotionBanner() {
			if (this.currentPromotion?.type === SpecialPromotionType.FREEBIES) {
				return !this.referralsInfo.rewards.find((reward) => reward.name === FreebiesTypes.WATERMARK_FREE);
			} else return this.currentPromotion && this.$route.name !== 'PricingPage';
		}

		get isInHomepage() {
			return this.$route.name === 'LibraryExploreList';
		}

		handleShowPromotionBanner() {
			if (!this.currentPromotion) OrganismMutations.setShowPromotionHeader(this.$store, null);
			if (this.isProOrAbove) OrganismMutations.setShowPromotionHeader(this.$store, null);
			if (this.isShowPromotionBanner) OrganismMutations.setShowPromotionHeader(this.$store, true);
		}

		handleScreenSize() {
			if (window.innerWidth <= 743) {
				this.isMobileScreen = true;
				this.isTabletScreen = false;
				this.isDesktopScreen = false;
			} else if (window.innerWidth <= 1024) {
				this.isMobileScreen = false;
				this.isTabletScreen = true;
				this.isDesktopScreen = false;
			} else {
				this.isMobileScreen = false;
				this.isTabletScreen = false;
				this.isDesktopScreen = true;
			}
			this.windowInnerWidth = window.innerWidth;
		}

		async handleWindowVisibilityChange() {
			const isVisible = document.visibilityState === 'visible';
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			if (isVisible) {
				if (!webSocketManager.isConnected && !webSocketManager.isConnecting) {
					try {
						AppMutations.setIsReconnectingSocket(this.$store, true);
						await webSocketManager.connect();
					} finally {
						AppMutations.setIsReconnectingSocket(this.$store, false);
					}
				}
			}
			AppMutations.setIsAppWindowActive(this.$store, isVisible);
		}

		handleScroll() {
			const $el = this.$refs.refMainLayout as HTMLElement;
			const scrollTop = $el.scrollTop;
			if (scrollTop > BACK_TO_TOP_SCROLL_OFFSET) {
				if (!this.isScrolled) this.isScrolled = true;
			} else if (this.isScrolled) this.isScrolled = false;
			const scrollToBottom = checkScrollToButtom($el, 400);
			if (scrollToBottom) this.emitLibraryScrollInjections();
		}

		emitLibraryScrollInjections() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.emitProviderInjections(CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT);
		}

		created() {
			this._handleWindowVisibilityChange = this.handleWindowVisibilityChange.bind(this);
			this._handleScreenSize = this.handleScreenSize.bind(this);
		}

		populatingAbTestingVariant() {
			if (this.abTesting) {
				this.abTestingVariant = Object.keys(this.abTesting).filter((item) => item !== 'id');
			}
		}

		mounted() {
			if (this.isShowPromotionBanner) OrganismMutations.setShowPromotionHeader(this.$store, true);
			this.populatingAbTestingVariant();
			storeOriginSources();
			window.addEventListener('resize', this._handleScreenSize);
			this.handleScreenSize();
			document.addEventListener('visibilitychange', this._handleWindowVisibilityChange);
			logger.log('Deployed from: ', process.env.BRANCH_ORIGIN);
		}

		beforeDestroy() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.resetProviderInjections(CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT);
			document.removeEventListener('visibilitychange', this._handleWindowVisibilityChange);
			window.removeEventListener('resize', this._handleScreenSize);
		}
	}
